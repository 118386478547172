<!-- 编辑SIM卡信息 -->
<template>
  <el-dialog
      width="30%"
      :append-to-body="true"
      :visible.sync="dialogTableVisible"
      title="编辑SIM卡信息"
      :close-on-click-modal="false"
      @close="close"
  >
    <el-form ref="form" :model="simItem" label-width="80px">
      <el-form-item label="MSISDN">
        <el-input v-model="simItem.msisdn"></el-input>
      </el-form-item>
      <el-form-item label="IMSI">
        <el-input v-model="simItem.imsi"></el-input>
      </el-form-item>
      <el-form-item label="SIM卡类型">
        <el-input v-model="simItem.simType"></el-input>
      </el-form-item>
      <el-form-item label="网络类型">
        <el-input v-model="simItem.networkType"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogTableVisible: false,
      simItem: {}
    }
  },
  methods: {
    show (item) {
      this.simItem = item
      this.dialogTableVisible = true
    },
    close () {
      this.simItem = {}
      this.dialogTableVisible = false
    },
    submit () {
      this.$api.save('sysCloudCard', this.simItem).then(res => {
        this.$message.success('SIM卡信息修改成功')
        this.$emit('on-submit', this.simItem)
        this.close()
      })
    }
  },
  mounted () {
  }
}
</script>
