<!-- 添加测试授权 -->
<template>
  <el-dialog
      title="设置测试授权（天）"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-input-number :min="1" v-model="licenseNum"></el-input-number>
    <div slot="footer" class="flex justify-between pt-4">
      <div>
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="submit" :disabled="licenseNum <= 0"
        >确定
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogVisible: false,
      licenseNum: 0,
      uniqueId: '',
      multipleSelection: []
    }
  },
  methods: {
    close () {
      this.dialogVisible = false
    },
    show ({
      imeiList,
      uniqueId
    }) {
      this.multipleSelection = imeiList
      this.uniqueId = uniqueId
      this.dialogVisible = true
    },
    submit () {
      if (this.licenseNum < 1) {
        this.$message.error('测试授权天数不能小于1')
        return
      }
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择设备')
        return
      }
      // const list = []
      // this.multipleSelection.forEach(item => {
      //   list.push({
      //     imei: item.imei,
      //     licenseNum: this.licenseNum
      //   })
      // })
      this.$api.customerDevice.customerDeviceUpdateV2({
        uniqueId: this.uniqueId,
        imeiList: this.multipleSelection.map(item => item.imei),
        feeCycle: this.licenseNum
      }).then(res => {
        this.$message.success('操作成功')
        this.close()
        this.$emit('on-submit')
      })
    }
  },
  mounted () {
  }
}
</script>
