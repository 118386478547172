<!--  -->
<template>
  <div>
    <el-card
        shadow="never"
        class="device-list"
        :body-style="{ height: '100%' }"
    >
      <AppTable
          ref="AppTable"
          :controller="tableController"
          :tableColumns="tableColumns"
          enableVTable
          @on-handleSelection-change="selectionOnChange"
          @on-clearFilter="tableOnClearFilter"
          :request-params="requestParams"
      >
        <template #imeiList>
          <el-button
              @click="inputImeiListVisible = true"
              :type="inputImeiListArr.length > 0 ? 'primary' : ''"
          >
            {{
              inputImeiListArr.length
                  ? `已填入 ${inputImeiListArr.length} 个IMEI`
                  : '批量填入IMEI号'
            }}
          </el-button>
        </template>
        <template #quick-operator-box>
          <div class="grid grid-flow-col gap-1">
            <el-button
                type="primary"
                size="mini"
                @click="addDevices"
                icon="el-icon-circle-plus-outline"
                v-if="type === 2"
            >分配设备
            </el-button>
            <el-button
                type="primary"
                size="mini"
                @click="addDevices({ testMode: true })"
                icon="el-icon-circle-plus-outline"
                v-if="type === 2"
            >分配测试设备
            </el-button>
            <el-button
                type="primary"
                size="mini"
                @click="getDeviceEncryptStr"
                icon="el-icon-circle-plus-outline"
                v-if="type === 2 && customerNode.deployType === 3"
            >下载设备授权文件
            </el-button>
            <el-button
                type="primary"
                size="mini"
                @click="getCustomerDeviceModelEncryptStr"
                icon="el-icon-circle-plus-outline"
                v-if="type === 2 && customerNode.deployType === 3"
            >下载设备型号文件
            </el-button>
            <template v-if="multipleSelection.length > 0">
              <el-button
                  v-if="type === 2 && checkAddLicenseBtn(false)"
                  type="primary"
                  size="mini"
                  @click="addLicense"
                  icon="el-icon-circle-plus-outline"
              >添加授权
              </el-button>
              <el-button
                  v-if="type === 2 && checkAddLicenseBtn(true)"
                  type="primary"
                  size="mini"
                  @click="addLicense({ testMode: true })"
                  icon="el-icon-circle-plus-outline"
              >添加测试授权
              </el-button>
              <el-button
                  size="mini"
                  @click="deleteBatchDevice"
                  icon="el-icon-circle-plus-outline"
                  type="danger"
                  v-if="type === 2 && checkDeleteDeviceBtn()"
              >取消分配
              </el-button>
              <el-button
                  size="mini"
                  @click="enableState(2)"
                  icon="el-icon-circle-check"
                  type="info"
              >禁用
              </el-button>
              <el-button
                  size="mini"
                  @click="enableState(1)"
                  icon="el-icon-circle-close"
                  type="success"
              >启用
              </el-button>
              <el-button
                  v-if="type === 2"
                  type="primary"
                  size="mini"
                  @click="changeDisplayModel"
                  icon="el-icon-circle-plus-outline"
              >修改展示型号
              </el-button>
              <el-button
                  size="mini"
                  @click="copyDeviceList"
                  icon="el-icon-document-copy"
                  :data-clipboard-text="imeiListJoinN"
                  id="copyImeiList"
              >
                复制已选设备
              </el-button>
            </template>
          </div>
          <p class="mt-2 text-xs text-red-500" v-if="addLicenseBtnTips">
            {{ addLicenseBtnTips }}
          </p>
        </template>
        <template #licenseNumAndUse="{ row }">
          <template v-if="row.licenseNum !== 0">
            {{ row.authLicenseDay - row.remainLicenseDay }} /
            {{ row.authLicenseDay }}
          </template>
          <template v-else> -</template>
        </template>

        <template #deviceType="{ row }">
          {{
            deviceTypeList.find((item) => item.deviceType === row.deviceType)
                .deviceTypeName
          }}
        </template>
        <template #deviceModel="{ row }">
          <el-popover
              placement="top-start"
              trigger="click"
              @show="getDeviceInfoByDeviceModel(row.deviceModel)"
              @hide="curDeviceObj = {}"
          >
            <div slot="reference" class="cursor-pointer">
              <span
                  class="border-b pb-0.5 border-gray-300 border-dashed truncate"
              >
                {{ row.deviceModel }}
              </span>
            </div>
            <deviceDefaultInfo
                v-if="row.deviceModel"
                class="mt-2"
                :deviceObj="curDeviceObj"
            ></deviceDefaultInfo>
          </el-popover>
        </template>

        <template #prodModel="{ row }">
          <span
              class="border-b pb-0.5 border-gray-300 border-dashed truncate"
              :title="row.prodModel"
          >
            {{ row.prodModel }}
          </span>
        </template>

        <template #validDtm="{ row }">
          <div class="flex flex-row" v-if="row.remainLicenseDay !== 0">
            <div>{{ row.takeDtm }}</div>
            <div class="mx-2">~</div>
            <div>{{ row.failureDtm }}</div>
          </div>
          <div v-else>-</div>
        </template>
        <template #customerName="{ row }">
          <p
              class="cursor-pointer underline truncate"
              @click="toCustomerDetail(row)"
          >
            {{ row.customerName }}
          </p>
        </template>
        <template #ptRemainLicenseDay="{ row }">
          <div
              class="flex flex-row"
              v-if="row.ptRemainLicenseDay < row.authLicenseDay"
          >
            <el-tooltip
                class="item"
                effect="dark"
                content="请注意：平台授权时间小于当前授权时间"
                placement="top"
            >
              <div class="text-red-500 flex align-items-center">
                <span class="">{{ row.ptRemainLicenseDay }}</span>
                <i class="text-sm el-icon-warning ml-0.5"></i>
              </div>
            </el-tooltip>
          </div>
          <div v-else>
            {{ row.ptRemainLicenseDay }}
          </div>
        </template>

        <template #iccid="{ row }">
          <div v-if="row.iccid" @click="simDetails(row)" class="cursor-pointer">
            {{ row.iccid }} [{{ row.yunMonthTraffic || 0 }}MB]
          </div>
        </template>
        <template #onlineState="{ row }">
          <div
              class="w-16 h-6 flex align-items-center justify-content-center text-xs text-green-500 bg-green-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-green-200 rounded"
              v-if="row.onlineState === 1"
          >
            在线
          </div>
          <div
              class="w-16 h-6 flex align-items-center justify-content-center text-xs text-red-500 bg-red-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-red-200 rounded"
              v-if="row.onlineState === 2"
          >
            离线
          </div>
        </template>

        <template #enableState="{ row }">
          <div
              class="w-16 h-6 flex align-items-center justify-content-center text-xs text-green-500 bg-green-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-green-200 rounded"
              v-if="row.enableState === 1"
          >
            已启用
          </div>
          <div
              class="w-16 h-6 flex align-items-center justify-content-center text-xs text-red-500 bg-red-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-red-200 rounded"
              v-if="row.enableState === 2"
          >
            未启用
          </div>
        </template>
        <template #assignState="{ row }">
          <div
              class="w-16 h-6 flex align-items-center justify-content-center text-xs text-green-500 bg-green-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-green-200 rounded"
              v-if="row.assignState === 2"
          >
            已分配
          </div>
          <div
              class="w-16 h-6 flex align-items-center justify-content-center text-xs text-red-500 bg-red-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-red-200 rounded"
              v-if="row.assignState === 1"
          >
            未分配
          </div>
        </template>
        <template #testing="{ row }">
          <div v-if="row.testing === 1">
            <i class="el-icon-star-on text-lg text-yellow-500"></i>
          </div>
        </template>

        <template #unitPrice="{ row }">
          <el-popover
              placement="top-start"
              trigger="click"
              @show="getBillInfoByImei(row.imei)"
              @hide="curBillObj = {}"
          >
            <div slot="reference" class="cursor-pointer">
              <span
                  class="border-b pb-0.5 border-gray-300 border-dashed"
                  v-if="row.unitPrice >= 0"
              >
                {{ row.unitPrice }}
              </span>
            </div>
            <deviceBillInfo
                v-if="row.imei"
                :billObj="curBillObj"
            ></deviceBillInfo>
          </el-popover>
        </template>
      </AppTable>
    </el-card>
    <el-dialog
        title="批量输入IMEI号"
        :visible.sync="inputImeiListVisible"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
    >
      <el-input
          v-model="inputImeiListValue"
          type="textarea"
          :rows="15"
          placeholder="请输入IMEI列表，一行一个。"
      ></el-input>
      <div class="flex justify-between mt-2">
        <el-button @click="clearImeiList">清空并关闭</el-button>
        <el-button type="primary" @click="submitImeiList">确定</el-button>
      </div>
    </el-dialog>
    <addDeviceDialog
        :customerInfo="customerInfo"
        ref="addDeviceDialog"
    ></addDeviceDialog>
    <simDetailsDialog ref="simDetailsDialog"></simDetailsDialog>
    <addTestLicenseDialog
        @on-submit="addTestLicenseOnSubmit"
        ref="addTestLicenseDialog"
    ></addTestLicenseDialog>
    <changeDisplayModelDialog
        @on-submit="reGetData"
        ref="changeDisplayModelDialog"
    ></changeDisplayModelDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import addDeviceDialog from 'components/client/addDeviceDialog'
import addTestLicenseDialog from '../device/addTestLicenseDialog.vue'
import simDetailsDialog from '../sim/simDetailsDialog.vue'
import deviceDefaultInfo from 'components/device/deviceDefaultInfo.vue'
import deviceBillInfo from 'components/bill/deviceBillInfoV2.vue'
import changeDisplayModelDialog from 'components/device/changeDisplayModelDialog.vue'
import { mapGetters } from 'vuex'
import Clipboard from 'clipboard'

export default {
  data () {
    return {
      tableColumns: [
        {
          label: '#',
          type: 'selection'
        },
        {
          label: 'IMEI',
          prop: 'imei',
          search: true,
          filterType: 'customFilter',
          filtersList: [],
          filterProp: 'imeiList',
          filterSlotName: 'imeiList',
          minWidth: '180px'
        },
        {
          label: 'ICCID',
          width: '200',
          type: 'customSlot',
          slotName: 'iccid'
        },
        {
          label: '授权天数',
          width: '96',
          type: 'customSlot',
          slotName: 'licenseNumAndUse'
        },
        {
          label: '有效日期',
          type: 'customSlot',
          slotName: 'validDtm',
          width: '300px'
        },
        {
          label: '客户名称',
          prop: 'customerId',
          type: 'customSlot',
          slotName: 'customerName',
          display: this.type === 1,
          filterType: 'headerFilterRadio',
          filtersList: []
        },
        {
          label: '平台剩余天数',
          width: '96',
          type: 'customSlot',
          slotName: 'ptRemainLicenseDay'
        },
        {
          label: '设备类型',
          prop: 'deviceType',
          type: 'customSlot',
          slotName: 'deviceType',
          filterType: 'headerFilterRadio',
          filtersList: [],
          filterOnChange: () => {
            this.changeDeviceType()
          }
        },
        {
          label: '设备型号',
          prop: 'deviceModel',
          type: 'customSlot',
          slotName: 'deviceModel',
          filterType: 'headerFilterCheckbox',
          width: 80,
          filtersList: [],
          filterProp: 'deviceModelList'
        },
        {
          label: '展示型号',
          prop: 'prodModel',
          type: 'customSlot',
          slotName: 'prodModel',
          width: 120
        },
        {
          label: '授权单价',
          prop: 'unitPrice',
          width: '80px',
          type: 'customSlot',
          slotName: 'unitPrice'
        },
        {
          label: '在线状态',
          type: 'customSlot',
          width: 96,
          slotName: 'onlineState',
          prop: 'onlineState',
          filterType: 'headerFilterRadio',
          filtersList: [
            {
              label: '不限',
              value: ''
            },
            {
              label: '在线',
              value: '1'
            },
            {
              label: '离线',
              value: '2'
            }
          ]
        },
        {
          label: '启用状态',
          type: 'customSlot',
          width: 96,
          slotName: 'enableState',
          prop: 'enableState',
          filterType: 'headerFilterRadio',
          filtersList: [
            {
              label: '不限',
              value: ''
            },
            {
              label: '已启用',
              value: '1'
            },
            {
              label: '未启用',
              value: '2'
            }
          ]
        },
        {
          label: '分配状态',
          type: 'customSlot',
          width: 96,
          slotName: 'assignState',
          prop: 'assignState',
          display: !this.customerInfo,
          filterType: 'headerFilterRadio',
          filtersList: [
            {
              label: '不限',
              value: ''
            },
            {
              label: '未分配',
              value: '1'
            },
            {
              label: '已分配',
              value: '2'
            }
          ]
        },
        {
          label: '测试设备',
          type: 'customSlot',
          width: 96,
          slotName: 'testing',
          prop: 'testing',
          filterType: 'headerFilterRadio',
          filtersList: [
            {
              label: '不限',
              value: ''
            },
            {
              label: '测试设备',
              value: '1'
            },
            {
              label: '正式设备',
              value: '2'
            }
          ]
        }
      ],
      requestParams: {
        customerInfoId: this.$route.query.id,
        deviceType: '',
        imeiList: [],
        deviceModelList: [],
        activateState: '',
        onlineState: '',
        enableState: '',
        assignState: '',
        testing: '',
        customerId: ''
      },
      deviceTypeList: [],
      multipleSelection: [],
      inputImeiListVisible: false,
      inputImeiListArr: [],
      inputImeiListValue: '',
      clientId: '',
      addLicenseBtnTips: '',
      curDeviceObj: {},
      curBillObj: {}
    }
  },
  computed: {
    imeiListJoinN () {
      const imeiList = this.multipleSelection.map(item => item.imei)
      if (imeiList.length > 0) {
        return imeiList.join('\n')
      } else {
        return ''
      }
    },
    tableController () {
      switch (this.type) {
        case 1:
          return 'sysDevice'
        case 2:
          return 'customerDevice'
        default:
          return ''
      }
    },
    ...mapGetters(['clientDeviceListMultipleList'])
  },
  props: {
    customerInfo: {},
    type: {
      type: Number,
      default: () => {
        // 1.全局设备库 2.代理详情页的设备库
        return 1
      }
    },
    customerNode: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    addDeviceDialog,
    simDetailsDialog,
    addTestLicenseDialog,
    deviceDefaultInfo,
    deviceBillInfo,
    changeDisplayModelDialog
  },
  methods: {
    /**
     * 添加IMEI
     * @param testMode
     */
    addDevices ({ testMode = false }) {
      if (this.type === 1) {
        // 当前在设备库页面
        this.$store.commit('setClientDeviceListMultipleList', JSON.parse(JSON.stringify(this.multipleSelection)))
        this.$emit('on-show-select-custom')
        return false
      }
      this.$router.push({
        name: 'clientAddDevice',
        query: {
          clientId: this.clientId,
          uniqueId: this.customerInfo.uniqueId,
          testMode
        }
      })
    },
    /**
     * 获取所有设备类型
     */
    getDeviceTypeFindAll () {
      const findAllUrl = this.type === 1 ? 'sysDeviceType' : 'customerDeviceType'
      this.$api.findAll(findAllUrl, {
        id: this.clientId
      }).then(res => {
        this.deviceTypeList = [{
          deviceTypeName: '全部',
          deviceType: ''
        }, ...res.data]
        const item = this.tableColumns.find(item => item.prop === 'deviceType')
        if (item) {
          this.$set(item, 'filtersList', this.deviceTypeList.map(item => {
            return {
              label: item.deviceTypeName,
              value: item.deviceType
            }
          }))
        }
      })
    },
    changeDeviceType (type) {
      this.$refs.AppTable.clearSelection()
      // this.requestParams.deviceType = type
      this.requestParams.deviceModelList = []
      this.getSysDeviceModel()
    },
    // 根据设备类型获取设备型号
    getSysDeviceModel () {
      if (this.requestParams.deviceType) {
        this.$api.sysDevice.findDeviceModel({ deviceType: this.requestParams.deviceType }).then(res => {
          const result = this.tableColumns.find(item => item.prop === 'deviceModel')
          result.filtersList = res.data.map(val => {
            return {
              value: val,
              label: val
            }
          })
        })
      } else {
        this.tableColumns.forEach(item => {
          if (item.prop === 'deviceModel') {
            item.filtersList = []
          }
        })
      }
    },
    selectionOnChange (multipleSelection) {
      this.multipleSelection = multipleSelection
    },
    addLicense ({ testMode }) {
      this.$store.commit('setClientDeviceListMultipleList', JSON.parse(JSON.stringify(this.multipleSelection)))
      if (testMode) {
        this.$refs.addTestLicenseDialog.show({
          imeiList: JSON.parse(JSON.stringify(this.multipleSelection)),
          uniqueId: this.customerInfo.uniqueId
        })
      } else {
        this.$router.push({
          name: 'clientAddLicense',
          query: {
            clientId: this.clientId,
            uniqueId: this.customerInfo.uniqueId
          }
        })
      }
    },
    clearImeiList () {
      this.inputImeiListValue = ''
      this.inputImeiListVisible = false
      this.requestParams.imeiList = []
      this.handlerInputImeiListArr()
      this.reGetData()
    },
    submitImeiList () {
      this.handlerInputImeiListArr()
      this.requestParams.imeiList = this.inputImeiListArr
      this.inputImeiListVisible = false
      this.$refs.AppTable.page = 0
      this.reGetData()
    },
    handlerInputImeiListArr () {
      let arr = this.inputImeiListValue.split('\n')
      arr = arr.reduce((prev, cur) => {
        cur = cur.replace(/\s*/g, '')
        if (cur) {
          return [...prev, cur]
        } else {
          return prev
        }
      }, [])
      this.inputImeiListArr = arr
    },
    reGetData () {
      this.$refs.AppTable.reGetData()
    },
    deleteBatchDevice () {
      const ids = this.multipleSelection.map(item => item.id)
      this.$confirm(`是否移除已选的${ids.length}台设备？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.customerDevice.deleteBatch(ids).then(() => {
          this.$message.success('操作成功')
          this.$refs.AppTable.reGetData()
        })
      }).catch(() => {
      })
    },
    /**
     * 启用禁用
     * @param enableState
     */
    enableState (enableState) {
      const successFunc = () => {
        this.$refs.AppTable.reGetData()
        this.$refs.AppTable.clearSelection()
      }
      let ids = []
      this.$confirm(`是否确定${enableState === 1 ? '启用' : '禁用'}已选的${this.multipleSelection.length}台设备?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.type === 1) {
          ids = this.multipleSelection.map(item => item.sysDeviceId)
          this.$api.sysDevice.updateBatch({
            ids,
            enableState
          }).then(successFunc)
        } else if (this.type === 2) {
          ids = this.multipleSelection.map(item => item.sysDeviceId)
          this.$api.customerDevice.updateBatch({
            ids,
            enableState
          }).then(successFunc)
        }
      }).catch(() => {
      })
    },
    copyDeviceList () {
      const clipboard = new Clipboard('#copyImeiList')
      clipboard.on('success', () => {
        this.$message.success('已复制到粘贴板')
        clipboard.destroy()
      })
    },
    tableOnClearFilter () {
      this.inputImeiListValue = ''
      this.inputImeiListArr = []
      // this.$refs.AppTable.clearSelection()
    },
    toCustomerDetail (item) {
      this.$router.push({
        name: 'clientDetails',
        query: {
          id: item.customerInfoId
        }
      })
    },
    setClientId (clientId) {
      this.clientId = clientId
    },
    /**
     * 查看SIM卡详情
     * @param item
     */
    simDetails (item) {
      this.$nextTick(() => {
        this.$refs.simDetailsDialog.show({ sysCloudCardId: item.sysCloudCardId })
      })
    },
    getTestingArr () {
      return this.multipleSelection.map(item => item.testing).reduce((prev, cur) => {
        return prev.includes(cur) ? prev : prev.concat(cur)
      }, [])
    },
    /**
     * 判断显示“添加授权”、“设置测试授权”
     * @param testMode
     */
    checkAddLicenseBtn (testMode) {
      const testingArr = this.getTestingArr()
      // 如果选中的设备不是同一种类型（测试设备或者正式设备），则无法操作授权按钮
      if (testingArr.length > 1) {
        this.addLicenseBtnTips = '提示：测试设备与正式设备，无法同时操作授权按钮'
        return false
      } else if (testMode && testingArr[0] === 1) {
        // 选中设备为测试设备，且当前操作为测试模式
        this.addLicenseBtnTips = ''
        return true
      } else if (!testMode && testingArr[0] === 2) {
        // 选中设备为正式设备，且当前操作为正式模式
        this.addLicenseBtnTips = ''
        return true
      }
    },
    checkDeleteDeviceBtn () {
      const testingArr = this.getTestingArr()
      // 如果选中的设备不是同一种类型（测试设备或者正式设备），则无法操作授权按钮
      if (testingArr.length > 1) {
        this.deleteDeviceBtnTips = '提示：测试设备与正式设备，无法同时操作删除按钮'
        return false
      } else if (testingArr[0] === 1) {
        // 选中设备为测试设备
        this.deleteDeviceBtnTips = ''
        return true
      } else if (testingArr[0] === 2) {
        // 选中设备为正式设备
        this.deleteDeviceBtnTips = ''
        return true
      }
    },
    addTestLicenseOnSubmit () {
      this.$refs.AppTable.reGetData()
      this.$refs.AppTable.clearSelection()
    },
    selectDeviceModel (deviceModel) {
      return new Promise(resolve => {
        this.$api.sysDevice.findByDeviceModel({
          deviceModel
        }).then(res => {
          resolve(res)
        })
      })
    },
    async getDeviceInfoByDeviceModel (deviceModel) {
      const res = await this.selectDeviceModel(deviceModel)
      this.curDeviceObj = res.data
    },
    getClientList () {
      this.$api.page('customerInfo', {
        size: 999
      }).then(res => {
        const filterList = res.data.content.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
        filterList.unshift({
          label: '全部',
          value: ''
        })
        this.tableColumns.find(item => item.prop === 'customerId').filtersList = filterList
      })
    },
    getBillInfoByImei (imei) {
      this.$api.billImei.findByImei({
        imei
      }).then(res => {
        this.curBillObj = res.data
      })
    },
    // 修改设备展示型号
    async changeDisplayModel () {
      // 检查this.multipleSelection数组中，deviceModel字段是否都相同
      const deviceModelArr = this.multipleSelection.map(item => item.deviceModel)
      const idList = this.multipleSelection.map(item => item.id)
      const deviceModel = deviceModelArr[0]
      if (deviceModelArr.every(item => item === deviceModel)) {
        // const res = await this.selectDeviceModel(deviceModel)
        this.$refs.changeDisplayModelDialog.show({
          deviceModel,
          customerInfoId: this.multipleSelection[0].customerInfoId,
          idList
        })
      } else {
        this.$message.error('请选择相同的设备型号')
      }
    },
    /**
     * 下载授权文件
     */
    getDeviceEncryptStr () {
      const loading = this.$loading({
        lock: true,
        text: '正在生成授权文件，请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(255,255,255,0.7)'
      })
      this.$api.customerDevice.getDeviceEncryptStr({
        customerId: this.clientId
      }).then(res => {
        const { data } = res
        const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
        const fileName = 'imei.txt'
        if ('download' in document.createElement('a')) { // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else { // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      }).finally(() => {
        loading.close()
      })
    },
    /**
     * 下载设备型号文件
     */
    getCustomerDeviceModelEncryptStr () {
      const loading = this.$loading({
        lock: true,
        text: '正在生成设备型号文件，请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(255,255,255,0.7)'
      })
      this.$api.customerDeviceModel.getEncryptStr({
        customerId: this.clientId
      }).then(res => {
        const { data } = res
        const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
        const fileName = `${this.clientId}_device_model_${Date.parse(new Date())}.txt`
        if ('download' in document.createElement('a')) { // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else { // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      }).finally(() => {
        loading.close()
      })
    }
  },
  mounted () {
    this.clientId = this.$route.query.id
    this.getDeviceTypeFindAll()
    if (this.type === 1) {
      this.getClientList()
    }
  },
  activated () {
    this.clientId = this.$route.query.id
    this.$store.commit('clearClientDeviceListMultipleList')
    this.reGetData()
    // 返回页面时 重新给多选框赋值
    // this.multipleSelection = JSON.parse(JSON.stringify(this.clientDeviceListMultipleList))
  }
}
</script>
