<!-- 修改展示型号 -->
<template>
  <el-dialog
      title="修改展示型号"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <div class="flex justify-between">
      <el-select v-model="deviceModelJointId" class="w-9/12">
        <el-option
            v-for="item in deviceModelList"
            :key="item.id"
            :label="item.prodModel"
            :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button @click="submit">确认修改</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogVisible: false,
      deviceModel: '',
      customerInfoId: null,
      deviceModelList: [],
      deviceModelJointId: null,
      idList: []
    }
  },
  methods: {
    close () {
      this.initData()
      this.dialogVisible = false
    },
    initData () {
      this.deviceModel = ''
      this.customerInfoId = null
      this.deviceModelList = []
      this.deviceModelJointId = null
      this.idList = []
    },
    show ({
      deviceModel,
      customerInfoId,
      idList
    }) {
      this.deviceModel = deviceModel
      this.customerInfoId = customerInfoId
      this.idList = idList
      this.getDeviceModelList()
      this.dialogVisible = true
    },
    getDeviceModelList () {
      this.$api.sysDevice.getDeviceModelList({
        deviceModel: this.deviceModel,
        customerId: this.customerInfoId
      }).then(res => {
        this.deviceModelList = res.data
      })
    },
    submit () {
      this.$api.customerDevice.updateDeviceModelJointId({
        ids: this.idList,
        deviceModelJointId: this.deviceModelJointId
      }).then(() => {
        this.$emit('on-submit')
        this.close()
      })
    }
  },
  mounted () {
  }
}
</script>
