<!-- 账单信息列表 -->
<template>
  <div>
    <div class="info text-xs leading-8 text-gray-600 flex-1">
      <div class="item flex flex-row">
        <div class="label">账单编号:</div>
        <div class="value ml-2">
          {{ billInfo.billNo }}
        </div>
      </div>
      <div class="item flex flex-row">
        <div class="label">所属客户:</div>
        <div class="value ml-2">
          {{ billInfo.customerName }}
        </div>
      </div>
      <div class="item flex flex-row">
        <div class="label">账单内容:</div>
        <div class="value ml-2 flex-1">
          <div
              v-for="(item, index) in billInfo.billDetailList"
              :key="index"
              class="py-2 border-b"
          >
            <p class="text-xs font-bold">{{ item.projectName }}</p>
            <p class="text-xs text-gray-500 flex justify-between mt-1">
              <span>数量x{{ item.quantity }}</span>
              <span>可用数量x{{ item.quantity - item.assignNum }}</span>
              <span class="ml-1">总价：{{ item.totalFee }}元</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {}
  },
  props: {
    billInfo: {
      type: Object
    }
  },
  methods: {},
  mounted () {
  }
}
</script>
