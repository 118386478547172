<!-- SIM卡详情 -->
<template>
  <el-dialog
      width="50%"
      :append-to-body="true"
      :visible.sync="dialogTableVisible"
      title="SIM卡信息"
      :close-on-click-modal="false"
      @close="close"
  >
    <!--    <div slot="title" class="flex flex-row">-->
    <!--      <div>SIM卡信息</div>-->
    <!--      <el-button @click="editSimInfo" type="primary" size="mini" class="ml-4">编辑SIM卡</el-button>-->
    <!--    </div>-->
    <div class="grid grid-cols-2 leading-8">
      <div class="flex flex-row">
        <div class="label w-24">MSISDN:</div>
        <div class="value">{{ simItem.msisdn }}</div>
      </div>
      <!--      <div class="flex flex-row">-->
      <!--        <div class="label w-24">卡状态:</div>-->
      <!--        <div class="value">{{ simItem.useState === 1 ? '未使用' : '已使用' }}</div>-->
      <!--      </div>-->
      <div class="flex flex-row">
        <div class="label w-24">IMSI:</div>
        <div class="value">{{ simItem.imsi }}</div>
      </div>
      <!--      <div class="flex flex-row">-->
      <!--        <div class="label w-24">SIM卡类型:</div>-->
      <!--        <div class="value">{{ simItem.simType }}</div>-->
      <!--      </div>-->
      <div class="flex flex-row">
        <div class="label w-24">ICCID:</div>
        <div class="value">{{ simItem.iccid }}</div>
      </div>
      <!--      <div class="flex flex-row">-->
      <!--        <div class="label w-24">网络类型:</div>-->
      <!--        <div class="value">{{ simItem.networkType }}</div>-->
      <!--      </div>-->
      <div class="flex flex-row">
        <div class="label w-24">客户:</div>
        <div class="value">{{ simItem.customerName }}</div>
      </div>
      <!--      <div class="flex flex-row">-->
      <!--        <div class="label w-24">套餐:</div>-->
      <!--        <div class="value">{{ simItem.sysComboName }}</div>-->
      <!--      </div>-->
      <!--      <div class="flex flex-row">-->
      <!--        <div class="label w-24">总用量:</div>-->
      <!--        <div class="value">{{ simItem.totalTraffic }}MB</div>-->
      <!--      </div>-->
      <div class="flex flex-row">
        <div class="label w-24">本月用量:</div>
        <div class="value">{{ simItem.yunMonthTraffic }}MB</div>
      </div>
    </div>
    <div class="chart h-96 bg-gray-50 mt-4">
      <el-date-picker
          :clearable="false"
          v-model="dateValue"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="dateValueChange"
      >
      </el-date-picker>
      <div class="chart">
        <div id="netWorkChart" ref="netWorkChart" class="h-96"></div>
      </div>
    </div>
    <editSimInfoDialog
        ref="editSimInfoDialog"
        @on-submit="onEditSubmit"
    ></editSimInfoDialog>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less">
.label {
  color: #666;
}
</style>
<script type="text/ecmascript-6">
import editSimInfoDialog from 'components/sim/editSimInfoDialog'
import timeUtils from '../../utils/timeUtils'

export default {
  data () {
    return {
      dialogTableVisible: false,
      simItem: {},
      option: {},
      dateValue: []
    }
  },
  components: {
    editSimInfoDialog
  },
  methods: {
    async show ({ sysCloudCardId }) {
      this.simItem = await this.getSimData(sysCloudCardId)
      this.dialogTableVisible = true
      this.initDateValue()
      this.getTrafficData()
    },
    getSimData (id) {
      return new Promise(resolve => {
        this.$api.select('sysCloudCard', id).then(res => {
          resolve(res.data)
        })
      })
    },
    close () {
      this.simItem = {}
      this.dialogTableVisible = false
    },
    editSimInfo () {
      this.$refs.editSimInfoDialog.show(JSON.parse(JSON.stringify(this.simItem)))
    },
    onEditSubmit (item) {
      this.simItem = item
      this.$emit('on-edit-submit', this.simItem)
    },
    initDateValue () {
      this.dateValue = [
        (timeUtils.getBeforeDays({ daysCount: 30 }))[30],
        timeUtils.getToday()
      ]
    },
    getTrafficData () {
      this.$api.findAll('traffic', {
        startDate: this.dateValue[0],
        endDate: this.dateValue[1],
        iccid: this.simItem.iccid
      }).then(res => {
        const xAxisData = res.data.map(item => item.dateStr)
        const yAxisData = res.data.map(item => item.traffic)
        this.draw(xAxisData, yAxisData)
      })
    },
    draw (xAxisData, yAxisData) {
      const netWorkChart = this.$echarts.init(this.$refs.netWorkChart)
      this.option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(255,158,68)'
                },
                {
                  offset: 1,
                  color: 'rgb(255, 70, 131)'
                }
              ])
            },
            data: yAxisData,
            type: 'line'
          }
        ]
      }
      netWorkChart.setOption(this.option)
    },
    dateValueChange () {
      this.dateValue = [
        timeUtils.formatDay(this.dateValue[0]).join('-'),
        timeUtils.formatDay(this.dateValue[1]).join('-')
      ]
      this.getTrafficData()
    }
  },
  mounted () {
  }
}
</script>
