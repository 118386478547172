<!-- 设备账单信息 -->
<template>
  <div class="">
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2 flex justify-between align-items-center"
    >
      账单信息
      <span class="text-xs cursor-pointer text-gray-500" @click="toBillDetail"
      >进入账单详情</span
      >
    </div>
    <div class="bill-info mt-4 text-xs" v-if="billObj && billObj.billDetailNo">
      <div class="item flex flex-row py-1.5 px-2">
        <div class="label">账单编号:</div>
        <div class="value ml-2">
          {{ billObj.billNo }}
        </div>
      </div>
      <div class="item flex flex-row py-1.5 px-2">
        <div class="label">账单规格编号:</div>
        <div class="value ml-2">
          {{ billObj.billDetailNo }}
        </div>
      </div>
      <div class="item flex flex-row py-1.5 px-2">
        <div class="label">规格名称:</div>
        <div class="value ml-2">
          {{ billObj.billProjectName }}
        </div>
      </div>
      <div class="item flex flex-row py-1.5 px-2">
        <div class="label">规格单价:</div>
        <div class="value ml-2">
          {{ billObj.unitPrice }}
        </div>
      </div>
      <div class="item flex flex-row py-1.5 px-2">
        <div class="label">授权时长:</div>
        <div class="value ml-2">
          {{ billObj.feeCycle }}
        </div>
      </div>
      <div class="item flex flex-row py-1.5 px-2">
        <div class="label">客户名称:</div>
        <div class="value ml-2">
          {{ billObj.customerName }}
        </div>
      </div>
      <div class="item flex flex-row py-1.5 px-2">
        <div class="label">账单操作人:</div>
        <div class="value ml-2">
          {{ billObj.createName }}
        </div>
      </div>
      <div class="item flex flex-row py-1.5 px-2">
        <div class="label">支付时间:</div>
        <div class="value ml-2">
          {{ billObj.payDtm }}
        </div>
      </div>
      <div class="item flex flex-row py-1.5 px-2">
        <div class="label">账单备注:</div>
        <div class="value ml-2">
          {{ billObj.remark }}
        </div>
      </div>
    </div>
    <div v-else class="h-60 flex align-items-center justify-content-center">
      暂无账单信息
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.bill-info {
  .item {
    .label {
      width: 100px;
    }
  }
}
</style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {}
  },
  props: {
    billObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {},
  methods: {
    toBillDetail () {
      this.$router.push({
        name: 'billContent',
        query: {
          id: this.billObj.billId
        }
      })
    }
  },
  mounted () {
  }
}
</script>
