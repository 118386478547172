<!-- 添加设备 -->
<template>
  <el-dialog
      title="添加设备"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="50%"
  >
    <template slot="title">
      <div>
        {{
          `添加${curDeviceType.deviceTypeName}${testMode ? '测试' : '正式'}设备`
        }}
        <span class="tips text-xs text-gray-500 ml-2"
        >请确认当前填入的IMEI为{{
            `${curDeviceType.deviceTypeName}的${testMode ? '测试' : '正式'}设备`
          }}</span
        >
      </div>
    </template>
    <div class="grid grid-cols-3 gap-4" v-if="step === 1">
      <div
          class="min-h-80 border-dashed border-gray-500 border p-3 col-span-2"
          :class="{ 'col-span-3': testMode }"
      >
        <span class="text-gray-800 text-xsm font-bold">选择型号：</span>
        <el-select
            v-model="deviceModel"
            class="ml-2 w-4/6"
            size="small"
            @change="deviceModelOnChange"
        >
          <el-option
              v-for="(item, index) in deviceModelList"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <deviceDefaultInfo
            v-if="deviceModel"
            class="mt-2"
            :deviceObj="deviceObj"
        ></deviceDefaultInfo>
      </div>
      <div
          class="min-h-80 border-dashed border-gray-500 border p-3"
          v-if="!testMode"
      >
        <span class="text-gray-800 text-xsm font-bold">选择账单：</span>
        <el-select v-model="billNo" size="small" class="ml-2 w-4/6">
          <el-option
              v-for="(item, index) in deviceBillList"
              :key="index"
              :value="item.billNo"
              :label="item.billNo"
              class="h-16 flex justify-between align-items-center"
              style="width: 420px"
          >
            <div class="text-sm font-bold flex flex-col leading-6">
              <div>账单号：{{ item.billNo }}</div>
              <div class="text-xs font-light">金额：{{ item.totalFee }}元</div>
            </div>
            <div class="text-xs text-gray-400 flex flex-col leading-5">
              <div>出账时间：{{ item.offDtm }}</div>
              <div>支付时间：{{ item.payDtm }}</div>
            </div>
          </el-option>
        </el-select>
        <billDefaultInfo
            v-if="billNo"
            :billInfo="billInfo"
            class="mt-2"
        ></billDefaultInfo>
      </div>
    </div>
    <div class="text-center" v-if="step === 1">
      <el-button
          class="w-3/6 mt-6"
          type="primary"
          @click="toStep(2)"
          :disabled="
          !testMode && step === 1 && (deviceModel === '' || billNo === '')
        "
      >确认账单信息，进入下一步
      </el-button>
    </div>
    <div v-if="step === 2">
      <div class="flex align-items-center justify-between">
        <div>
          <el-button
              size="small"
              @click="showAddDeviceBatch"
              type="primary"
              :disabled="isShowAddDeviceBatch"
          >
            快速填入IMEI
          </el-button>
          <el-button size="small" @click="showDeviceListDialog" type="primary">
            设备库中选择
          </el-button>
          <span class="ml-2">当前账单可使用数量：{{ canUseTotal }}</span>
        </div>
        <div>
          <!--          <el-button size="mini" type="danger" v-if="imeiData.length > 0" @click="clearImeiList">清空下方表格-->
          <!--          </el-button>-->
        </div>
      </div>
      <div class="py-4 border-b border-gray-100" v-if="isShowAddDeviceBatch">
        <div class="text-gray-800 text-sm font-bold">请在下方填入IMEI号</div>
        <el-input
            class="mt-1"
            type="textarea"
            :rows="5"
            placeholder="填入IMEI号，一行填写一个IMEI号"
            v-model="addDeviceBatchValue"
        ></el-input>
        <div class="mt-2">
          <el-button size="small" type="primary" @click="addDeviceBatch"
          >批量填入({{ addDeviceBatchArr.length }}台)
          </el-button>
          <el-button size="small" @click="closeAddDeviceBatch"
          >清空并关闭
          </el-button>
        </div>
      </div>

      <InfiniteScrollTable
          v-if="step === 2"
          ref="ref_infinite_table"
          :getMoreData="getMoreDataFn"
          :scrollData="imeiData"
          :loadConfig="loadConfig"
          @setTableData="setTableDataFn"
      >
        <el-table
            :data="tableData"
            style="width: 100%"
            height="550"
            ref="elTable"
        >
          <el-table-column prop="imei" label="IMEI">
            <template v-slot="{ row }">
              <!--              {{ row.imei }}-->
              <el-input size="small" v-model="row.imei"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="产品规格" v-if="!testMode">
            <template v-slot="{ row }">
              <el-select v-model="row.billProjectNo">
                <el-option
                    v-for="(item, index) in getProjectList(billNo)"
                    :key="index"
                    :label="item.projectName"
                    :disabled="item.disabled"
                    :value="item.billProjectNo"
                    class="h-16 flex justify-between align-items-center"
                    style="width: 420px"
                >
                  <div class="text-sm font-bold flex flex-col leading-6 w-full">
                    <div>项目名称：{{ item.projectName }}</div>
                    <div
                        class="text-xs font-light flex flex-row justify-between align-items-center"
                    >
                      <div>单价：{{ item.unitPrice }}元</div>
                      <div>
                        账单剩余可用：{{
                          item.quantity - item.assignNum
                        }}{{ item.unit }}
                      </div>
                    </div>
                  </div>
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column width="80" label="#">
            <template v-slot="{ $index }">
              <el-button
                  size="mini"
                  type="danger"
                  @click="removeImei($index)"
                  icon="el-icon-delete"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </InfiniteScrollTable>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import billDefaultInfo from 'components/bill/billDefaultInfo'
import deviceDefaultInfo from 'components/device/deviceDefaultInfo'

export default {
  data () {
    return {
      dialogVisible: false,
      deviceType: '',
      testMode: false,
      curDeviceType: {},
      step: 1,
      deviceModel: '',
      deviceModelList: [],
      deviceBillList: [],
      customerInfo: {},
      billNo: '',
      deviceObj: {},
      isShowAddDeviceBatch: false,
      addDeviceBatchValue: '',
      imeiData: [],
      imeiModel: {
        imei: '',
        billProjectNo: ''
      },
      tableData: [],
      loadConfig: {
        totalCount: 0,
        itemHeight: 65
      }
    }
  },
  computed: {
    billInfo () {
      if (!this.deviceBillList) {
        return {}
      }
      const item = this.deviceBillList.find(item => item.billNo === this.billNo)
      if (item) {
        return item
      } else {
        return {}
      }
    },
    // 当前账单可用的总数量
    canUseTotal () {
      if (this.billNo) {
        return ((this.getProjectList(this.billNo)).map(item => {
          return item.quantity - item.assignNum
        })).reduce((prev, cur) => {
          return prev + cur
        }, 0)
      } else {
        return 0
      }
    },
    /**
     * 将addDeviceBatchValue填入的内容转换为数组
     */
    addDeviceBatchArr () {
      let arr = this.addDeviceBatchValue.split('\n')
      arr = arr.reduce((prev, cur) => {
        cur = cur.replace(/\s*/g, '')
        if (cur) {
          return [...prev, cur]
        } else {
          return prev
        }
      }, [])
      return arr
    }
  },
  components: {
    billDefaultInfo,
    deviceDefaultInfo
  },
  methods: {
    close () {
      this.dialogVisible = false
    },
    show ({
      deviceType,
      testMode,
      curDeviceType,
      customerInfo
    }) {
      this.dialogVisible = true
      this.deviceType = deviceType
      this.testMode = testMode
      this.curDeviceType = curDeviceType
      this.customerInfo = customerInfo
      this.getSysDeviceModel()
    },
    deviceModelOnChange () {
      this.getDeviceBillList()
      this.findByDeviceModel()
    },
    /**
     * 获取设备所对应的账单内容
     */
    getDeviceBillList () {
      this.$api.bill.getDeviceBillList({
        deviceType: this.deviceType,
        deviceModel: this.deviceModel,
        uniqueId: this.customerInfo.uniqueId
      }).then(res => {
        this.deviceBillList = res.data
      })
    },
    getSysDeviceModel () {
      const func = (res) => {
        this.deviceModelList = res.data.map(val => {
          return {
            value: val,
            label: val
          }
        })
      }
      if (this.testMode) {
        // 测试设备
      } else {
        // 正式设备
        this.$api.bill.getDeviceModelList({
          deviceType: this.deviceType,
          uniqueId: this.customerInfo.uniqueId
        }).then(func)
      }
    },
    findByDeviceModel () {
      this.$api.findAll('sysDevice', {
        deviceModel: this.deviceModel
      }).then(res => {
        this.deviceObj = res.data[0]
      })
    },
    toStep (step) {
      this.step = step
    },
    getProjectList (billNo) {
      if (billNo) {
        const bill = this.deviceBillList.find(item => item.billNo === billNo)
        return bill.billDetailList.map(item => {
          // 检查当前行选中的产品规格 是否超出可用数量
          // const projectItemCanUse = item.quantity - item.assignNum// 当前产品规格可用数量
          // const isSelectList = this.imeiData.filter(imeiItem => {
          //   if (imeiItem.billProjectNo === item.billProjectNo) {
          //     return imeiItem
          //   }
          // }) // 当前已选中该产品规格的imei
          // if (isSelectList.length === 0 && projectItemCanUse > 0) {
          //   item.disabled = false
          // } else {
          //   item.disabled = isSelectList.length >= projectItemCanUse
          // }
          return item
        })
      } else {
        return []
      }
    },
    showAddDeviceBatch () {
      this.isShowAddDeviceBatch = true
    },
    closeAddDeviceBatch () {
      this.addDeviceBatchValue = ''
      this.isShowAddDeviceBatch = false
    },
    /**
     * 批量填入IMEI号
     */
    addDeviceBatch () {
      if (!this.testMode && !this.checkImeiArrLength()) {
        return false
      }
      if (!this.checkDeviceBatchArr(this.addDeviceBatchArr)) {
        return false
      }
      if (!this.checkExistImeiWithImeiList(this.addDeviceBatchArr)) {
        return false
      }
      this.addDeviceBatchArr.forEach(imei => {
        this.imeiData.push({
          imei,
          billProjectNo: this.imeiModel.billProjectNo
        })
      })
      this.loadConfig.totalCount = this.imeiData.length
      this.$refs.ref_infinite_table.updateTableSize()
      this.closeAddDeviceBatch()
    },
    /**
     * 判断是否可以继续追加设备
     * @returns {boolean}
     */
    checkImeiArrLength () {
      const addDeviceBatchArrLength = this.addDeviceBatchArr.length || 1
      const imeiDataLength = this.imeiData.length
      if (addDeviceBatchArrLength + imeiDataLength > this.canUseTotal) {
        this.$message.error(`填入的数量超出账单可使用数量，当前账单可使用数量为${this.canUseTotal}台`)
        return false
      } else {
        return true
      }
    },
    /**
     * 查找添加的IMEI是否与下方列表重复
     * @param addDeviceBatchArr
     * @returns {boolean}
     */
    checkExistImeiWithImeiList (addDeviceBatchArr) {
      const filterResult = this.imeiData.filter(item => addDeviceBatchArr.indexOf(item.imei) > -1)
      if (filterResult.length === 0) {
        return true
      } else {
        const imeiArr = filterResult.map(item => item.imei)
        this.$message.error(`${imeiArr.join('、')} 设备已填入`)
        return false
      }
    },
    /**
     * 判断当前输入的内容，是否有重复
     * @param addDeviceBatchArr
     */
    checkDeviceBatchArr (addDeviceBatchArr) {
      const existList = []
      addDeviceBatchArr.reduce((prev, cur) => {
        if (prev.indexOf(cur) > -1) {
          // 已存在
          existList.push(cur)
          return prev
        } else {
          return [...prev, cur]
        }
      }, [])
      if (existList.length > 0) {
        this.$message.error(`${existList.join('、')} 填写重复`)
      }
      return existList.length === 0
    },
    setTableDataFn (arr) {
      this.tableData = arr
    },
    getMoreDataFn () {
    },
    showDeviceListDialog () {
    }
  },
  mounted () {
  }
}
</script>
